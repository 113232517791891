import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavComponent } from './components/nav/nav.component';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatStepperModule } from '@angular/material/stepper';
import { ActionDescriptorComponent } from './components/action-descriptor/action-descriptor.component';
import { SortByPipe } from './pipes/sort-by.pipe';
import { RequestIconPipe } from './pipes/request-icon.pipe';
import { ActionIconPipe } from './pipes/action-icon.pipe';
import { MsToSPipe } from './pipes/ms-to-s.pipe';
import { MatGridListModule } from '@angular/material/grid-list';
import { FormatFileSizePipe } from './pipes/format-file-size.pipe';
import { GalleryComponent } from './components/gallery/gallery.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ModalComponent } from './components/modal/modal.component';
import { PortalModule } from '@angular/cdk/portal';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CustomProgressComponent } from './components/custom-progress/custom-progress.component';
import { MatChipsModule } from '@angular/material/chips';
import { RawDataVisualisationComponent } from './components/raw-data-visualisation/raw-data-visualisation.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { StatusIconPipe } from './pipes/status-icon.pipe';
import { MaxValuePipe } from './pipes/max.pipe';
import { MatSliderModule } from '@angular/material/slider';
import { AlphabeticalSortingPipe } from './pipes/alphabetical-sorting.pipe';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SidebarLogsComponent } from './components/sidebar-logs/sidebar-logs.component';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CalculTotalRequestSizePipe } from './pipes/calcul-total-request-size.pipe';
import { RequestUrlPrettierPipe } from './pipes/request-url-prettier.pipe';
import { MatSortModule } from '@angular/material/sort';
import { StatusTranslatePipe } from './pipes/status-translate.pipe';
import { HasRoleDirective } from './directives/has-role.directive';
import { NgChartsModule } from 'ng2-charts';
import { DeviceIconPipe } from './pipes/device-icon.pipe';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { LinesChartComponent } from './components/lines-chart/lines-chart.component';
import { IntroCardComponent } from './components/intro-card/intro-card.component';
import { SuccessRateCardComponent } from './components/success-rate-card/success-rate-card.component';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SuccessLineChartComponent } from './components/success-line-chart/success-line-chart.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';

@NgModule({
  declarations: [
    NavComponent,
    ActionDescriptorComponent,
    SortByPipe,
    RequestIconPipe,
    ActionIconPipe,
    MsToSPipe,
    StatusIconPipe,
    FormatFileSizePipe,
    CalculTotalRequestSizePipe,
    RequestUrlPrettierPipe,
    StatusTranslatePipe,
    GalleryComponent,
    ModalComponent,
    CustomProgressComponent,
    RawDataVisualisationComponent,
    MaxValuePipe,
    AlphabeticalSortingPipe,
    SidebarLogsComponent,
    HasRoleDirective,
    DeviceIconPipe,
    DoughnutChartComponent,
    LinesChartComponent,
    IntroCardComponent,
    SuccessRateCardComponent,
    SidebarComponent,
    SuccessLineChartComponent,
    BarChartComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatDividerModule,
    MatCardModule,
    MatSlideToggleModule,
    PortalModule,
    MatTooltipModule,
    NgxJsonViewerModule,
    MatSelectModule,
    MatSliderModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatTabsModule,
    MatButtonToggleModule,
    MatSortModule,
    NgChartsModule,
    MatMenuModule,
  ],
  exports: [
    MatSidenavModule,
    NavComponent,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCardModule,
    MatDividerModule,
    MatProgressBarModule,
    MatButtonModule,
    MatSnackBarModule,
    MatTabsModule,
    MatTableModule,
    MatDialogModule,
    MatMenuModule,
    MatSelectModule,
    MatExpansionModule,
    MatTooltipModule,
    MatStepperModule,
    ActionDescriptorComponent,
    MatToolbarModule,
    SortByPipe,
    RequestIconPipe,
    ActionIconPipe,
    MsToSPipe,
    StatusIconPipe,
    CalculTotalRequestSizePipe,
    RequestUrlPrettierPipe,
    StatusTranslatePipe,
    MatGridListModule,
    FormatFileSizePipe,
    DeviceIconPipe,
    GalleryComponent,
    MatSlideToggleModule,
    DragDropModule,
    PortalModule,
    MatProgressSpinnerModule,
    CustomProgressComponent,
    MatChipsModule,
    RawDataVisualisationComponent,
    MatSliderModule,
    AlphabeticalSortingPipe,
    MatNativeDateModule,
    MatDatepickerModule,
    SidebarLogsComponent,
    MatButtonToggleModule,
    MatSortModule,
    HasRoleDirective,
    NgChartsModule,
    DoughnutChartComponent,
    LinesChartComponent,
    IntroCardComponent,
    SuccessRateCardComponent,
    SidebarComponent,
    ScrollingModule,
    SuccessLineChartComponent,
    BarChartComponent,
  ],
  providers: [HasRoleDirective],
})
export class SharedModule {}
