import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class IsConnectedGuard {
  constructor(private authService: AuthService, private router: Router) {}

  canActivate():
  | Observable<boolean | UrlTree>
  | Promise<boolean | UrlTree>
  | boolean
  | UrlTree {
    return this.authService.get().pipe(
      catchError(() => this.router.navigate(['/login'])),
      map(() => true),
    );
  }
}
