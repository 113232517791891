import { NavigationStart, Router, Event } from '@angular/router';
import { Injectable } from '@angular/core';
import {
  CdkPortalOutletAttachedRef,
  ComponentPortal,
  ComponentType,
} from '@angular/cdk/portal';
import { MatSidenav, MatDrawerToggleResult } from '@angular/material/sidenav';
import { from, Subject, Observable, BehaviorSubject } from 'rxjs';

import {
  RightSidebarTableType,
  RightSidebarArgs,
  RightSidebarTableTypeString,
} from '@shared/models';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RightSidebarService {
  panel: MatSidenav;

  private panelPortalRef$ = new BehaviorSubject<RightSidebarTableTypeString>(
    null,
  );

  private panelPortal$ = new Subject<ComponentPortal<RightSidebarTableType>>();

  private tabOpened$ = new BehaviorSubject<boolean>(false);

  private panelPortalArgs: RightSidebarArgs;

  get panelPortal(): Observable<ComponentPortal<RightSidebarTableType>> {
    return from(this.panelPortal$);
  }

  constructor(private router: Router) {
    router.events
      .pipe(filter((event: Event) => event instanceof NavigationStart))
      .subscribe(() => {
        this.close();
      });
  }

  panelPortalName(): Observable<RightSidebarTableTypeString> {
    return this.panelPortalRef$;
  }

  isTabOpened(): Observable<boolean> {
    return this.tabOpened$;
  }

  setPanelContent(
    componentName: RightSidebarTableTypeString,
    componentRef: ComponentType<RightSidebarTableType> | null,
    arg: RightSidebarArgs,
  ): void {
    this.panelPortalArgs = arg;
    this.panelPortalRef$.next(componentName);
    this.panelPortal$.next(
      componentRef ? new ComponentPortal(componentRef) : undefined,
    );
  }

  attach(ref: CdkPortalOutletAttachedRef) {
    const target = ref as any;
    Object.keys(this.panelPortalArgs || {}).forEach((element) => {
      const arg = this.panelPortalArgs[element as keyof RightSidebarArgs];
      target.instance[element] = arg;
    });
  }

  clearPanelPortal(): void {
    this.panelPortal$.next(undefined);
    this.panelPortalArgs = {};
  }

  open(
    componentName: RightSidebarTableTypeString,
    componentRef: ComponentType<RightSidebarTableType>,
    args?: RightSidebarArgs,
  ): void {
    if (
      componentName !== this.panelPortalRef$.getValue() ||
      (componentName === this.panelPortalRef$.getValue() &&
        args !== this.panelPortalArgs)
    ) {
      this.tabOpened$.next(true);
      this.panel.close();
    }
    this.setPanelContent(componentName, componentRef, args as RightSidebarArgs);
    this.panel.open();
  }

  toggle(
    componentName: RightSidebarTableTypeString,
    componentRef: ComponentType<RightSidebarTableType>,
    arg?: RightSidebarArgs,
  ): void {
    if (componentName !== this.panelPortalRef$.getValue()) {
      return this.open(componentName, componentRef, arg);
    }
    this.close();
  }

  close(): Promise<MatDrawerToggleResult> {
    this.tabOpened$.next(false);
    this.setPanelContent(null, null, {});
    return this.panel.close();
  }
}
