import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  OnInit,
} from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { Role } from '@shared/models';

@Directive({
  selector: '[appHasRole]',
})
export class HasRoleDirective implements OnInit {
  private role: Array<keyof typeof Role> = [];

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
  ) {}

  @Input() set appHasRole(role: Array<keyof typeof Role>) {
    this.role = role;
  }

  ngOnInit(): void {
    this.updateView();
  }

  private updateView(): void {
    const user = this.authService.user$.getValue();
    if (!user) {
      return this.viewContainer.clear();
    }

    if (this.role.includes(user.role.name)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }

    this.viewContainer.clear();
  }
}
