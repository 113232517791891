<mat-toolbar color="secondary">
  <!-- <button
    mat-icon-button
    aria-label="Example icon-button with menu icon"
    (click)="handleMenuBtnToggle()"
    *ngIf="user$ | async"
  >
    <mat-icon>menu</mat-icon>
  </button> -->
  <button type="button" mat-button color="secondary" [routerLink]="['/']">
    NAOSAKI
  </button>

  <aside class="is-left">
    <div class="flex align-center">
      <mat-icon>
        {{ toggleControl.value ? "light_mode" : "dark_mode" }}
      </mat-icon>
      <mat-slide-toggle
        [formControl]="toggleControl"
        class="mr-8"
      ></mat-slide-toggle>
    </div>
    <button mat-icon-button [matMenuTriggerFor]="lang">
      <mat-icon>language</mat-icon>
    </button>
    <button *ngIf="user$ | async" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>menu</mat-icon>
    </button>
  </aside>
</mat-toolbar>

<mat-menu #lang="matMenu">
  <button mat-menu-item (click)="langRedirect('fr')">Francais</button>
  <button mat-menu-item (click)="langRedirect('en')">English</button>
</mat-menu>

<mat-menu #menu="matMenu">
  <button mat-menu-item [routerLink]="['/settings']">Paramètre</button>
  <button mat-menu-item (click)="logout()" i18n="app main header | menu">
    Déconnexion
  </button>
</mat-menu>
