import { Validators } from '@angular/forms';

const emailValidort = {
  validators: Validators.compose([
    Validators.required,
    Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$'),
  ]),
  updateOn: 'blur',
};

export default emailValidort;
