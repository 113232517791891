import { Validators } from '@angular/forms';

const passwordValidator = {
  validators: Validators.compose([
    Validators.minLength(12),
    Validators.required,
    Validators.pattern(
      '(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$',
    ),
  ]),
};

export default passwordValidator;
