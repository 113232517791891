<main class="app-container">
  <app-nav (menuMenuBtnToggle)="drawer.toggle()"></app-nav>
  <mat-sidenav-container class="main-container" autosize [hasBackdrop]="false">
    <mat-sidenav #drawer class="main-container-sidenav" mode="over">
      <app-sidebar *ngIf="isNavOpen$ | async"></app-sidebar>
    </mat-sidenav>

    <mat-sidenav-content class="main-container-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>

    <mat-sidenav
      #rightPanel
      class="panel right-panel"
      position="end"
      mode="over"
      [autoFocus]="false"
    >
      <ng-template
        [cdkPortalOutlet]="rightSidebarService.panelPortal | async"
        (attached)="rightSidebarService.attach($event)"
      ></ng-template>
    </mat-sidenav>
  </mat-sidenav-container>
</main>
