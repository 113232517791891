import { ChartConfiguration } from 'chart.js';

export const linesConfig: ChartConfiguration<'line'>['options'] = {
  elements: {
    line: {
      tension: 0.6,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 10,
      },
    },
    x: {
      ticks: {
        display: true,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: { display: false },
  },
};

export const doughnutConfig: ChartConfiguration<'doughnut'>['options'] = {
  cutout: '90%',
};

export const barConfig: ChartConfiguration<'bar'>['options'] = {
  responsive: true,
  maintainAspectRatio: false,
  scales: {
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
    x: {
      ticks: {
        display: false,
      },
      grid: {
        display: true,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    decimation: {
      enabled: false,
    },
  },
};
