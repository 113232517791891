import { MatSnackBar } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';
import { environment } from 'src/environments/environment';
import { snackbars } from '@workers';

const localNotifCode = {
  swVersionReady: {
    message: `Une nouvelle version de l'application est disponible.
      Veuillez actualiser la page pour activer les nouvelles fonctionnalités.`,
    type: 'info',
    actionText: "J'actualise",
  },
  swVersionInstallationFailed: {
    message:
      "Une erreur est survenue lors de la mise à jour de l'application. Veuillez actualiser la page pour réessayer.",
    type: 'error',
    actionText: "J'actualise",
  },
  swVersionCheckError: {
    message:
      "Une erreur est survenue lors de la vérification de la version de l'application. Veuillez contacter le support.",
    type: 'error',
    actionText: 'OK',
  },
};

@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerService {
  constructor(private updates: SwUpdate, private matSnackBar: MatSnackBar) {}

  init(): void {
    if (environment.production) {
      this.handleUnrecoverableState();
      this.handleUpdateOnLoad();
      this.checkForUpdates();
    }
  }

  checkForUpdates(): void {
    const swInterval$ = interval(1000 * 60);

    swInterval$.subscribe(async () => {
      try {
        const shouldUpdate = await this.updates.checkForUpdate();
        if (shouldUpdate) {
          this.notification(localNotifCode.swVersionReady);
        }
      } catch (err) {
        this.notification(localNotifCode.swVersionCheckError);
      }
    });
  }

  handleUnrecoverableState(): void {
    this.updates.unrecoverable.subscribe(() => {
      this.notification(localNotifCode.swVersionInstallationFailed);
    });
  }

  handleUpdateOnLoad(): void {
    this.updates.versionUpdates.subscribe((evt) => {
      if (evt.type === 'VERSION_READY') {
        this.notification(localNotifCode.swVersionReady);
        return;
      }
      if (evt.type === 'VERSION_INSTALLATION_FAILED') {
        this.notification(localNotifCode.swVersionInstallationFailed);
        return;
      }
    });
  }

  private notification(notif: {
    message: string;
    actionText: string;
    type: string;
  }): void {
    const snackbarRef = this.matSnackBar.open(notif.message, notif.actionText, {
      ...snackbars.error,
      duration: -1,
    });

    snackbarRef.onAction().subscribe(() => {
      window.location.reload();
    });
  }
}
