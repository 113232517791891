export const requiredMsg = {
  type: 'required',
  msg: $localize`Ce champ est requis`,
};

const email = [
  requiredMsg,
  {
    type: 'pattern',
    msg: $localize`L'email n'est pas valide`,
  },
];

const password = [
  requiredMsg,
  {
    type: 'minlength',
    msg: $localize`minimum 12 caractères`,
  },
  {
    type: 'pattern',
    msg: $localize`minimum une minuscule, une majuscule et un nombre`,
  },
];

export const authMessages = {
  email,
  firstName: [requiredMsg],
  lastName: [requiredMsg],
  password,
};
