import { AuthService } from '@core/services/auth.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
  @Output() menuMenuBtnToggle = new EventEmitter();

  toggleControl = new FormControl(false);

  user$ = this.authService.user$;

  constructor(private authService: AuthService, private router: Router) {}

  ngOnInit(): void {
    this.toggleControl.valueChanges.subscribe((value) => {
      if (value) {
        document.documentElement.classList.add('is-dark-mode');
        document.documentElement.classList.remove('is-light-mode');
      } else {
        document.documentElement.classList.add('is-light-mode');
        document.documentElement.classList.remove('is-dark-mode');
      }
    });

    const darkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.toggleControl.setValue(darkMode);

    window
      .matchMedia('(prefers-color-scheme: dark)')
      .addEventListener('change', (e) => {
        this.toggleControl.setValue(e.matches);
      });
  }

  handleMenuBtnToggle(): void {
    this.menuMenuBtnToggle.emit();
  }

  langRedirect(lang: 'fr' | 'en'): void {
    const langSet = this.router.url.split('/')[1];
    if (langSet !== lang) {
      window.location.href = `/${lang}`;
    }
  }

  logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
