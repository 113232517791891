import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IsConnectedGuard } from './core/guards/is-connected.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () =>
      import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: 'confirm-email',
    loadChildren: () =>
      import('./modules/confirm-email/confirm-email.module').then(
        (m) => m.ConfirmEmailModule,
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./modules/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule,
      ),
  },
  {
    path: 'reset-password',
    loadChildren: () =>
      import('./modules/reset-password/reset-password.module').then(
        (m) => m.ResetPasswordModule,
      ),
  },
  {
    path: '',
    loadChildren: () =>
      import('./modules/dashboard/dashboard.module').then(
        (m) => m.DashboardModule,
      ),
    canActivate: [IsConnectedGuard],
  },
  {
    path: 'bots',
    loadChildren: () =>
      import('./modules/bot/bot.module').then((m) => m.BotModule),
    canActivate: [IsConnectedGuard],
  },
  {
    path: 'analyses',
    loadChildren: () =>
      import('./modules/analysis/analysis.module').then(
        (m) => m.AnalysisModule,
      ),
    canActivate: [IsConnectedGuard],
  },
  {
    path: 'settings',
    loadChildren: () =>
      import('./modules/settings/settings.module').then(
        (m) => m.SettingsModule,
      ),
    canActivate: [IsConnectedGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
