import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { AuthService } from '@core/services/auth.service';
import { RightSidebarService } from '@core/services/right-sidebar.service';
import { ServiceWorkerService } from '@core/services/service-worker.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  isNavOpen$: Observable<boolean> = this.authService.user$.pipe(
    map((user) => !!user),
  );

  @ViewChild('rightPanel', { static: true }) private rightPanel: MatSidenav;

  constructor(
    private authService: AuthService,
    public rightSidebarService: RightSidebarService,
    private serviceWorkerService: ServiceWorkerService,
  ) {}

  ngOnInit(): void {
    this.rightSidebarService.panel = this.rightPanel;
    this.serviceWorkerService.init();
  }
}
