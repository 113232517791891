<aside class="sidebar-container">
  <header class="sidebar-container-header">
    <h3>
      <span i18n="component sidebar général | salutation">hello</span>
      {{ (user$ | async)?.firstName }}
    </h3>
    <mat-divider></mat-divider>
  </header>

  <section class="sidebar-container-content">
    <ul class="sidebar-container-content-list">
      <li class="sidebar-container-content-list-item">
        <button type="button" mat-flat-button (click)="logout()">
          <mat-icon>logout</mat-icon>
          <span i18n="component sidebar général | bouton déconnexion">
            déconnexion
          </span>
        </button>
      </li>
    </ul>
  </section>
</aside>
