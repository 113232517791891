import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { User } from 'src/app/shared/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  endpoint = `${environment.endpoint}/auth`;

  user$: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);

  accessToken: string | null;

  constructor(private httpClient: HttpClient) {
    this.accessToken = localStorage.getItem('accessToken') ?? '';
  }

  get(): Observable<User> {
    return this.httpClient.get<User>(`${this.endpoint}/me`).pipe(
      tap((user) => this.setUser({ user: user as User })),
      catchError(() => {
        this.logout();
        return {} as Observable<User>;
      }),
    );
  }

  login(
    loginData: { email: string; password: string },
    isAdmin?: boolean,
  ): Observable<{ token: string; user: User }> {
    return isAdmin
      ? this.httpClient.post<{ token: string; user: User }>(
        `${this.endpoint}/admin/email/login`,
        loginData,
      )
      : this.httpClient.post<{ token: string; user: User }>(
        `${this.endpoint}/email/login`,
        loginData,
      );
  }

  logout(): void {
    this.user$.next(null);
    this.accessToken = null;
    localStorage.removeItem('accessToken');
  }

  register(registerData: {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
  }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.endpoint}/email/register`,
      registerData,
    );
  }

  confirmEmail(hash: string): Observable<void> {
    return this.httpClient.post<void>(`${this.endpoint}/email/confirm`, {
      hash,
    });
  }

  setUser({ token, user }: { token?: string; user: User }): void {
    this.user$.next(user);
    if (token) {
      this.accessToken = token;
      localStorage.setItem('accessToken', token);
    }
  }

  forgotPassword(email: { email: string }): Observable<void> {
    return this.httpClient.post<void>(
      `${this.endpoint}/forgot/password`,
      email,
    );
  }

  resetPassword(data: { password: string; hash: string }): Observable<void> {
    return this.httpClient.post<void>(`${this.endpoint}/reset/password`, data);
  }
}
