import { MatSnackBarConfig } from '@angular/material/snack-bar';

const info = {
  duration: 5000,
  horizontalPosition: 'right',
  verticalPosition: 'top',
} as MatSnackBarConfig;

const error = {
  ...info,
  panelClass: ['error'],
} as MatSnackBarConfig;

const warn = {
  ...info,
  panelClass: ['warn'],
} as MatSnackBarConfig;

const success = {
  ...info,
  panelClass: ['success'],
} as MatSnackBarConfig;

export const snackbars = { success, error, warn, info };

export const snackbarsMsg = {
  verifySucceed: $localize`Vérication effectuée`,
  problemOccurs: $localize`Un problème est survenu`,
  formInProgress: $localize`Le formulaire est déjà en cours de traitement`,
  creationSucceed: $localize`Création réussie`,
  creationFailed: $localize`Impossible de procéder à la création`,
  updateSucceed: $localize`Mise à jour réussie`,
  updateFailed: $localize`Impossible de procéder à la mise à jour`,
  signInSucceed: $localize`Inscription réussie`,
  signInFailed: $localize`Impossible de procéder à l'inscription`,
  forgotPasswordSucceed: $localize`Les instructions ont été envoyées`,
  passwordReInitSucceed: $localize`Réinitialisation du mot de passe réussie`,
  passwordReInitFailed: $localize`Impossible de procéder à la réinitialisation du mot de passe`,
  errorOccurred: $localize`Une erreur est survenue`,
  logInSucceed: $localize`Connexion réussie`,
  logInFailed: $localize`Impossible de procéder à la connexion`,
  dataCopied: $localize`Données copiées`,
};
